<template>
  <!--解除隔离弹窗-->
  <el-dialog v-if="isArrange" title="解除隔离" :visible.sync="isArrange" width="800px" :before-close="closeArrange">
    <div class="zctc">
      <el-form style="width:100%" :rules="rules" ref="redform" :model="zcredform" label-width="180px">

        <el-form-item  label="转出类型：" prop="transferType">
          <el-radio-group @change="trasHome()" v-model="zcredform.transferType">
            <el-radio label="1">解除管控</el-radio>
            <el-radio label="2">送医治疗</el-radio>
            <el-radio label="4" :disabled="this.unselect === '4'">居家隔离</el-radio>
            <el-radio label="5" :disabled="this.unselect === '5'">居家监测</el-radio>
            <el-radio label="11">转隔离点</el-radio>
            <el-radio label="9">其他</el-radio>
          </el-radio-group>
        </el-form-item>

        <P style="color:red; margin-bottom: 20px;margin-left:100px"
          v-if="this.tipsList.length > 0 && this.zcredform.transferType !== ''">{{ this.tipsList.find(item => {
              return item.transferType === this.zcredform.transferType
            }) ? this.tipsList.find(item => {
              return item.transferType === this.zcredform.transferType
            }).tips : ''
          }}</P>

        <el-form-item label="转出目的地：" prop="">
          <span style="position: relative;color: red; top: 0px;left: -107px;">*</span>
          <!-- 如果没有回显的类型或者没有678类型的就默认显示一个 -->
          <el-input
            v-if="zcredform.transferType == '' || zcredform.transferType == null || zcredform.transferType == 6 || zcredform.transferType == 7 || zcredform.transferType == 8"
            placeholder="请输入" />

          <!-- 选择类型为129的显示input -->
          <el-input v-if="zcredform.transferType == 1 || zcredform.transferType == 2 || zcredform.transferType == 9"
            style="width:90%" v-model="transferItem" placeholder="请输入" />
          <!-- 转出类型为11 酒店转隔离点的类型 -->
          <el-select v-if="zcredform.transferType == '11'" clearable filterable size="medium" v-model="homeitem"
            @change="isolationOrgChange($event)" placeholder="负责机构">
            <el-option v-for="(item, index) in isolationList" :key="index" :label="item.orgName" :value="item.orgCode" />
          </el-select>
          <!-- 选择类型为4 5 的 是select框 -->
          <el-select v-if="zcredform.transferType == 4 || zcredform.transferType == 5" clearable filterable size="medium"
            v-model="homeitem" @change="homeOrgChange($event)" placeholder="负责机构">
            <el-option v-for="(item, index) in homeList" :key="index" :label="item.orgName" :value="item.orgCode" />
          </el-select>
        </el-form-item>
        <P style="color:red; margin-bottom: 20px;margin-left:130px"
          v-if="this.address!=''&&this.address!=undefined">参考居民地址：{{this.address}}</P>
        <el-form-item label="备注说明：" prop="remark">
          <el-input style="width:100%" v-model="zcredform.remark" placeholder="请输入" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="this.print == ''" size="small" @click="printNotification">打印解除隔离告知书</el-button>
      <el-button v-if="this.dispatch == '6'" size="small" @click="sendNotification">发送解除隔离告知书</el-button>
      <el-button size="small" @click="closeArrange">取 消</el-button>
      <el-button size="small" type="primary" @click="submitFrom">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
let nowtime;
import {
  downloadRelieveSignBook,
  relieveIsolate,
  sendRelieveSignBook,
  reliveControlTips
} from '../../../api/QuarantineRegistration/quarantineRegistrationpop';
import { getRegLiveInfoApi } from '../../../api/Taskmanagement/upcomingTasks';
import { getBusinessOrgList, getAllIsolationOrgList } from "../../../api/SystemManagement/index.js"; //根据业务状态查询机构
import constants from "../../../common/constants";
import moment from "moment";
export default {
  name: "RelieveQuarantine",
  components: {},
  data() {
    return {
      transferItem: '',
      dispatch: "",
      print: "",
      sends: "",
      tipsList: [], //获取到的提示语数组
      select: "",
      unselect: "",
      homeitem: "",
      homeList: [],//居家隔离机构
      isolationList: [],//隔离点数据
      isArrange: false,
      zcredform: {
        applyRelieveTime: "",
        transferType: '',
        transferDestination: '',
        remark: "",
        // transferDestinationCode:"" //根据居家来查找负责的社区结构
      },
      rules: {
        // applyRelieveTime:[{ required: true, message: "请选择解除隔离时间", trigger: "blur" }],
        transferType: [{ required: true, message: "请选择转出类型", trigger: "blur" }],
        transferItem: [{ required: true, message: "请输入转出目的地", trigger: "blur" }],
        homeitem: [{ required: true, message: "请输入转出目的地", trigger: "blur" }],
        remark: [{ required: true, message: "请填写备注", trigger: "blur" }],
      },
      transfertypeselect: constants.TRANSFER_TYPE_SELECT, //转出类型
      regId: null,
      regIds: [],
      address:"",
      regTime: new Date()
    }
  },

  props: {
    // 此组件在多个组件中引用，该属性用于区别用于哪个组件
    inputType: {
      type: String,
      default: ""
    }
  },




  mounted() {

    // console.log('执行mounteddddddddddd')
    this.homeOrgList() //根据业务查询业务机构（居家机构）
    this.isolationPlaceList() //根据机构权限，查询隔离点列表（启动的状态）
    // this.controlTips()
  },

  methods: {
    async show(item) {
      
      console.log(item,'打印任务管理，待办任务 接触隔离里面选中的人员信息')
      // 如果点的单个预解除隔离，那么就是regId，如果是选择批量预解除，就是regIds
      if (Array.isArray(item)) {
        if (item.length > 1) {
          this.regId = null;
          this.regIds = item.map(item => item.id);
          this.print = '0'; //批量不显示打印告知书
          // this.sends = '0'; //批量不显示发送告知书
        }
        this.regIds = item.map(item => item.id);
      } else {
        // console.log(this.inputType,'打印inputype')
        // 单个人的时候，如果登记渠道为小程序才显示 发送隔离告知书
        this.regIds =null
        this.dispatch = item.regChannel;
        this.regId = item.id;
        this.address = item.address;//如果地址不为 "" 则展示
        // console.log(this.address,'打印地址')
        // // 查询提示语
        let params = {
          id: item.id,
          source: "1"
        }
        let res = await reliveControlTips({ params });
        // console.log(res,'打印reswwwwwww')
        this.tipsList = res.data.data.tips; //获取到的提示语数组
        this.unselect = res.data.data.unselect; //不选中类型
        this.select = res.data.data.select; //获取到的选中的转出类型
        // console.log(this.select,'this.select打印，有可能是数字，有可能为null')
        if (this.select !== null) {
          this.zcredform.transferType = this.select
        }
      }
      this.isArrange = true;
      this.listItem = item;
      // this.regId = item.id;
      this.zcredform = {
        applyRelieveTime: "",
        transferType: '1',
        transferDestination: '',
        remark: "",
        transferDestinationCode:"" //根据居家来查找负责的社区结构
      }

      // if (this.inputType === "待办任务") {
      //   this.getRegLiveInfo();
      // }
      this.getRegLiveInfo();
    },
    closeArrange() {
      this.isArrange = false;
    },




    // 清空选项
    trasHome() {
      this.homeitem = "";
      this.transferItem = "";
      this.zcredform.transferDestinationCode = "";
      this.zcredform.transferDestination="";
    },

    // 根据业务状态去查询机构（居家类型的）
    async homeOrgList() {
      let params = { homeIsolation: 1 }
      let { data } = await getBusinessOrgList({ params });
      if (data.code == 200) {
        this.homeList = data.data
      }
    },
    // 根据转出类型为隔离点的获取隔离点列表的数据
    async isolationPlaceList() {
      // let params = { orgCode: JSON.parse(localStorage.getItem("userdoctor")).orgCode }
      let { data } = await getAllIsolationOrgList();
      // console.log(data,'打印data')
      if (data.code == 200) {
        this.isolationList = data.data
      }
    },
    // 居家机构选择change
    homeOrgChange(orgCode) {
      let dir = this.homeList.find((item) => item.orgCode === orgCode);
      // console.log(dir,'打印dirrrr')
      this.homeitem = dir.orgName;
      this.zcredform.transferDestination = dir.orgName;
      this.zcredform.transferDestinationCode = dir.orgCode;
    },
    // 隔离点机构选择change
    isolationOrgChange(orgCode) {
      // console.log(orgCode)
      // console.log('选择隔离点的change事件')
      // 选中的机构的整个对象
      let selectIsolation = this.isolationList.find((item) => item.orgCode === orgCode);
      console.log(selectIsolation,'打印selectIsolation')
      this.homeitem = selectIsolation.orgName;
      this.zcredform.transferDestination = selectIsolation.orgName;
      this.zcredform.transferDestinationCode = selectIsolation.orgCode;
    },

    

    //  // 清空后选项
    //  trasHome(){
    //       this.homeitem = "";
    //       this.transferItem = "";
    //   },

    async printNotification() {
      let params = {
        regId: this.regId
      };
      try {
        let { data } = await downloadRelieveSignBook({ params });
        let url = window.URL.createObjectURL(data);
        window.open(url);
      } catch (error) { }
    },

    async sendNotification() {
      let params = {
        regId: this.regId
      };
      try {
        let { data } = await sendRelieveSignBook({ params });
        this.$notify({
          title: "解除隔离告知书发送",
          message: `${data.msg}`,
          dangerouslyUseHTMLString: true
        });
        this.isArrange = false;
      } catch (error) { }
    },


    // 确定隔离

    async submitFrom() {
      let params = {
        ...this.zcredform,
        regId: this.regId,
        regIds: (Array.isArray(this.regIds)&&this.regIds.length > 0) ? this.regIds : null,
        transferDestination: this.homeitem == "" ? this.transferItem : this.homeitem,
      };
      console.log(params,'打印参数')
      let { data } = await relieveIsolate(params);
      if (data.code == 200) {

        this.$message({
          message: "解除隔离成功",
          type: "success"
        });
        this.isArrange = false;
        this.$forceUpdate()
        if (this.inputType === "待办任务") {
          //调用父组件的方法 但是未找到此方法，待解决
          this.$forceUpdate()
          this.$parent.queryDataInfo();
        } else if (this.inputType === "居民详情") {
          this.$forceUpdate()
          this.$parent.getIsolateDetailByRegId(this.listItem)
        }
      } else {
        this.$message.error(data.msg);
        this.isArrange = true;
        // this.$message.warning("请把信息填写完整");
      }
    },


    // 数据回显
    getRegLiveInfo() {
      // 如果是单个点进来，那么要单个用户的数据回显
      if (this.regId !== null) {
        let params = { regId: this.regId };
        getRegLiveInfoApi({ params }).then(res => {
          let { code, data, msg } = res.data;
          // console.log(data,'回显dataaaaaa')
          if (code == 200) {
            this.zcredform = {
              applyRelieveTime: "",
              transferType: data.transferType == null ? this.select : data.transferType,
              // transferDestination: data.transferDestination,
              transferDestinationCode: data.transferDestinationCode == null ? '' : data.transferDestinationCode,
              remark: data.remark,
            }
            if (this.zcredform.transferType == '1' || this.zcredform.transferType == '2' || this.zcredform.transferType == '9') {
              this.transferItem = data.transferDestination  //回显input文本框
            } else {
              this.homeitem = data.transferDestination; //回显社区机构
            }
          } else {
            this.$message.error(msg)
          }
        })
      }
    },


  }
}
</script>
  
<style scoped lang="scss">

</style>
  
